<template>
  <div class="Expired">
    <div class="expired_content">
      <img src="@/assets/image/sgtest/expired/role.png" alt="" />
      <div class="text_wrap">
        <div class="high_light ended">{{ $t("survey_bg.p51") }}</div>
        <div class="redirect">
          <template v-if="$i18n.locale === 'en'">
            <span>{{ $t("survey_bg.p52") || "" }}</span>
            <router-link to="/home" class="high_light">{{ $t("survey_bg.p54") }} </router-link>
            <span>{{ $t("survey_bg.p55") }} {{ countDownSec }} {{ $t("survey_bg.p56") }}</span>
          </template>
          <template v-else>
            <span>{{ $t("survey_bg.p52") || "" }}&nbsp;{{ countDownSec }}&nbsp;{{ $t("survey_bg.p53") }}</span>
            <router-link to="/home" class="high_light">{{ $t("survey_bg.p54") }}</router-link>
            <span>{{ $t("survey_bg.p55") }}</span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Expired",
  data() {
    return {
      timer: null,
      countDownSec: 3,
    };
  },

  mounted() {
    this.countDown();
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    countDown() {
      this.timer = setTimeout(() => {
        this.countDownSec--;
        if (this.countDownSec > 0) {
          this.countDown();
        } else {
          this.$router.push("/home");
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.Expired {
  width: 100%;
  height: 100vh;
  background-image: url("../../../assets/image/sgtest/expired/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .expired_content {
    // display: flex;
    // justify-content: center;
    // flex-wrap: wrap;
    width: 63.8rem;
    height: 72.7rem;
    max-width: 638px;
    max-height: 727px;
    background-image: url("../../../assets/image/sgtest/expired/labmem_panel_finish.png");
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 4rem;
    img {
      width: 45.8rem;
      height: auto;
    }
    .text_wrap {
      width: 100%;
      padding: 0 10rem;
      font-size: 3.6rem;
      color: #696c71;
      text-align: center;
      .ended {
        margin-bottom: 2.4rem;
      }
      .high_light {
        width: 100%;
        font-weight: bold;
        color: #29a2ff;
      }
    }
  }
}
</style>
