<template>
  <div class="header_wrap">
    <header :class="{ screenshot: isScreenshot }">
      <router-link to="/" class="logo">
        <img :src="logoSrc" alt="" />
      </router-link>
      <div class="btn_wrap">
        <router-link to="/" target="_blank" class="to_home">
          <img :src="toHomeSrc" alt="" />
        </router-link>
        <a href="javascript:;" class="voice" @click="switchAudio">
          <img v-if="!isPaused" src="@/assets/image/sgtest/header/labmemtest_btn_bgmon.png" alt="" />
          <img v-else src="@/assets/image/sgtest/header/labmemtest_btn_bgmoff.png" alt="" />
        </a>
        <a href="javascript:;" class="lang" @click="langDialog = true">
          <img :src="currentLangIcon" alt="" />
        </a>
      </div>
    </header>
    <CommonDialog v-model="langDialog" className="lang_dialog">
      <img class="title_img" slot="title" :src="langDialogTitleSrc" alt="" />
      <ul class="lang_list" slot="content">
        <li v-for="(item, index) in langList" :key="index" :class="{ active: item.value === currentLang }">
          <a href="javascript:;" @click="handleSetLanguage(item.value)">
            <img class="icon" :src="item.dialogIcon" alt="" />
            <span class="label">{{ item.label }}</span>
            <div class="check"></div>
          </a>
        </li>
      </ul>
    </CommonDialog>
  </div>
</template>

<script>
import "../utils/flexible.js";
import {setRemUnit} from "../utils/flexible.js";
import Cookies from "js-cookie";
import CommonDialog from "./CommonDialog.vue";
export default {
  components: {
    CommonDialog,
  },
  props: {
    isScreenshot: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      audio: null,
      isPaused: true,
      audioSrc: "https://d3qgi0t347dz44.cloudfront.net/release/video/HumamCommunity_V2.mp3",
      langDialog: false,
    };
  },
  computed: {
    langList() {
      return [
        {
          label: "English",
          value: "en",
          icon: require("@/assets/image/sgtest/header/labmemtest_text_language_en.png"),
          dialogIcon: require("@/assets/image/sgtest/dialog/lang/labmem_text_en.png"),
        },
        {
          label: "日本語",
          value: "jp",
          icon: require("@/assets/image/sgtest/header/labmemtest_text_language_jp.png"),
          dialogIcon: require("@/assets/image/sgtest/dialog/lang/labmem_text_jp.png"),
        },
        {
          label: "繁體中文",
          value: "tc",
          icon: require("@/assets/image/sgtest/header/labmemtest_text_language_tc.png"),
          dialogIcon: require("@/assets/image/sgtest/dialog/lang/labmem_text_tc.png"),
        },
        {
          label: "简体中文",
          value: "sc",
          icon: require("@/assets/image/sgtest/header/labmemtest_text_language_sc.png"),
          dialogIcon: require("@/assets/image/sgtest/dialog/lang/labmem_text_sc.png"),
        },
        {
          label: "한국어",
          value: "kr",
          icon: require("@/assets/image/sgtest/header/labmemtest_text_language_kr.png"),
          dialogIcon: require("@/assets/image/sgtest/dialog/lang/labmem_text_kr.png"),
        },
      ];
    },
    currentLang() {
      return this.$i18n.locale;
    },
    currentLangIcon() {
      return this.langList.find((item) => item.value === this.currentLang).icon;
    },
    langDialogTitleSrc() {
      return require(`@/assets/image/sgtest/dialog/lang/labmem_text_language_${this.$i18n.locale}.png`);
    },
    logoSrc() {
      return require(`@/assets/image/sgtest/header/labmemtest_logo_${this.$i18n.locale}.png`);
    },
    toHomeSrc() {
      return require(`@/assets/image/sgtest/header/labmemtest_btn_download_${this.$i18n.locale}.png`);
    },
  },
  created() {
    this.setDocumentTitle();
    setRemUnit();
  },
  beforeDestroy() {
    this.stopAudio();
  },
  watch: {
    $route() {
      this.stopAudio();
    },
  },
  methods: {
    handleSetLanguage(lang) {
      Cookies.set("language", lang, { expires: 30 });
      this.$store.dispatch("setLanguage", lang);
      this.$i18n.locale = lang;
      this.langDialog = false;
      this.setDocumentTitle();
    },
    setDocumentTitle() {
      document.title = "";
      setTimeout(() => {
        document.title = this.$t("sgtest_bg.p61");
      }, 100);
    },
    createAudio() {
      if (this.audio) return;
      this.audio = new Audio(this.audioSrc);
      this.audio.loop = true;
      this.audio.play();
      this.isPaused = false;
    },
    switchAudio() {
      if (!this.audio) {
        this.createAudio();
        return;
      }
      if (this.audio.paused) {
        this.audio.play();
      } else {
        this.audio.pause();
      }
      this.isPaused = this.audio.paused;
    },
    stopAudio() {
      if (!this.audio) return;
      this.audio.pause(); // 停止播放音频
      this.audio.src = ""; // 移除音频源
      this.audio = null; // 解除引用
    },
  },
};
</script>
<style lang="scss" scoped>
header {
  position: relative;
  z-index: 10;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  .logo {
    width: 37.4rem;
    margin-left: 0.5rem;
  }
  .btn_wrap {
    display: flex;
    align-items: center;
    width: 30.7rem;
    height: 7rem;
    background-image: url("../../../assets/image/sgtest/header/labmemtest_frame_top.png");
    background-repeat: no-repeat;
    background-size: contain;
    .to_home {
      width: 14.4rem;
      margin-left: 1.2rem;
    }
    .voice {
      width: 4.8rem;
      margin-left: 1.4rem;
    }
    .lang {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4.8rem;
      height: 4.2rem;
      background-image: url("../../../assets/image/sgtest/header/labmemtest_frame_languagebtn.png");
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: 1.4rem;
      img {
        height: 1.7rem;
      }
    }
  }

  &.screenshot {
    .btn_wrap {
      visibility: hidden;
    }
  }
}
</style>

<style lang="scss">
.survey_dialog {
  &.lang_dialog {
    .title {
      .title_img {
        width: 34rem;
      }
    }
    .lang_list {
      padding: 0 4.5rem;
      li {
        width: 57.4rem;
        height: 11.4rem;
        padding-left: 5.4rem;
        padding-right: 5.4rem;
        background-image: url("../../../assets/image/sgtest/dialog/lang/labmem_frame_item.png");
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 2.6rem;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 100%;
          .icon {
            height: 3.2rem;
            margin-right: 5rem;
          }
          .label {
            font-size: 4rem;
            color: #898a8c;
            font-weight: bold;
          }
          .check {
            position: relative;
            width: 4.8rem;
            height: 4.8rem;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url("../../../assets/image/sgtest/dialog/lang/check.png");
          }
        }

        &.active {
          .label {
            color: #000000;
          }
          .check {
            background-image: url("../../../assets/image/sgtest/dialog/lang/checked.png");
          }
        }
      }
    }
  }
}
</style>
