export let setRemUnit;
(function flexible(window, document) {
  let docEl = document.documentElement;
  let dpr = window.devicePixelRatio || 1;
  const isMobile = () => {
    return !!navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
  };
  function setBodyFontSize() {
    if (document.body) {
      document.body.style.fontSize = 12 * dpr + "px";
    } else {
      document.addEventListener("DOMContentLoaded", setBodyFontSize);
    }
  }
  setBodyFontSize();
  setRemUnit = function setRemUnit() {
    const isMobileT = isMobile();
    const rate = isMobileT ? 75 : 192;
    let rem = docEl.clientWidth / rate;
    if (!isMobileT) {
      // PC端
      const windowHeight = window.innerHeight;
      const pageWidth = (windowHeight * 9) / 16;
      rem = pageWidth / 75;
    }
    docEl.style.fontSize = rem + "px";
  };
  setRemUnit();
  window.addEventListener("resize", setRemUnit);
  window.addEventListener("pageshow", function (e) {
    if (e.persisted) {
      setRemUnit();
    }
  });
  if (dpr >= 2) {
    let fakeBody = document.createElement("body");
    let testElement = document.createElement("div");
    testElement.style.border = ".5px solid transparent";
    fakeBody.appendChild(testElement);
    docEl.appendChild(fakeBody);
    if (testElement.offsetHeight === 1) {
      docEl.classList.add("hairlines");
    }
    docEl.removeChild(fakeBody);
  }
})(window, document);
