<template>
  <div class="ScoreNum">
    <img v-for="(item, index) in scoreArr" :key="index" :src="item" alt="" />
  </div>
</template>

<script>
export default {
  name: "ScoreNum",
  props: {
    score: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    scoreArr() {
      return this.score
        .toString()
        .split("")
        .map((num) => {
          return require(`@/assets/image/sgtest/result/num/${num}.png`);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.ScoreNum {
  display: flex;
  justify-content: center;
  width: 30rem;
  img {
    max-width: none;
    height: 13rem;
  }
}
</style>
