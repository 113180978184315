<template>
  <div class="easter_egg">
    <div class="role_wrap">
      <div class="pop">
        <p :class="$i18n.locale">{{ $t("sgtest_bg.p37") }}</p>
      </div>
      <div class="role"></div>
      <div class="btn btn_selected" @click="startTest">
        <img :src="startTestText" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
export default {
  data() {
    return {
      isShow: true,
    };
  },
  computed: {
    startTestText() {
      return require(`@/assets/image/sgtest/easter_egg/labmemtest_text_nextbtn_${this.$i18n.locale}.png`);
    },
  },
  mounted() {
    this.animation();
    this.sendLog({
      f3: "1",
      f4: "1",
      f5: "0",
    });
  },
  methods: {
    animation() {
      gsap.from(".easter_egg .role", {
        scale: 0.1,
        // x: "100%",
        // y: 0,
        duration: 0.3,
      });
      gsap.from(".easter_egg .pop", {
        // scale: 1,
        x: "-300%",
        // y: 0,
        duration: 0.3,
      });

      gsap.from(".easter_egg .btn", {
        // scale: 1,
        x: "400%",
        // y: "400%",
        duration: 0.6,
      });
    },
    startTest() {
      this.$emit("startTest", true);
    },
    sendLog(log) {
      this.$emit("sendLog", log);
    },
  },
};
</script>

<style lang="scss" scoped>
.easter_egg {
  margin-bottom: -6rem;
  .role_wrap {
    position: relative;
    .pop {
      position: absolute;
      top: -19rem;
      left: 2.6rem;
      width: 61.5rem;
      height: 33.6rem;
      padding-top: 4rem;
      padding-right: 4rem;
      padding-bottom: 12rem;
      padding-left: 4rem;
      background-image: url("../../../assets/image/sgtest/easter_egg/labmemtest_frame_chat.png");
      background-repeat: no-repeat;
      background-size: cover;
      p {
        font-size: 2.8rem;
        line-height: 4.2rem;
        color: #545454;
        transform: rotate(-3deg);
        white-space: pre-wrap;
        &.en {
          font-size: 2.4rem;
          line-height: 3.6rem;
        }
        &.tc,
        &.sc,
        &.kr {
          line-height: 4.8rem;
        }
      }
    }
    .role {
      position: relative;
      z-index: 2;
      width: 74.9rem;
      height: 105.9rem;
      background-image: url("../../../assets/image/sgtest/easter_egg/labmemtest_img_role.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 76rem;
      left: 50%;
      z-index: 3;
      transform: translateX(-50%);
      width: 44rem;
      height: 12rem;
      background-image: url("../../../assets/image/sgtest/easter_egg/labmemtest_frame_nextbtn.png");
      background-repeat: no-repeat;
      background-size: cover;
      padding-bottom: 1.6rem;
      padding-right: 0.8rem;
      img {
        display: block;
        width: 22.5rem;
      }
    }
  }
}
</style>
