<template>
  <div class="Result" :class="{ screenshot: isScreenshot }">
    <div class="result_content">
      <div class="score_form">
        <div class="nickname_row">
          <div class="label" :class="$i18n.locale">{{ translate("sgtest_bg.p40") }}</div>
          <div class="value">{{ userInfo.nickname }}</div>
        </div>
        <div class="score_row">
          <div class="label" :class="$i18n.locale">{{ translate("sgtest_bg.p44") }}</div>
          <div class="value">
            <ScoreNum :score="resultContent.score"></ScoreNum>
            <img v-if="resultContent.score >= 100" src="@/assets/image/sgtest/result/badge_02.png" alt="" />
            <img v-else src="@/assets/image/sgtest/result/badge_01.png" alt="" />
          </div>
        </div>
        <div class="desc">
          <template v-if="resultContent.score >= 60">
            <span>{{ translate("sgtest_bg.p46") }}</span>
            <span class="no"> No.{{ passCount }}</span>
            <span v-if="$i18n.locale === 'en'">!</span>
          </template>
          <span v-else>{{ translate("sgtest_bg.p45") }}</span>
        </div>
      </div>
      <div class="role_section">
        <img class="banner" :src="roleInfo.bg" alt="" />
        <div class="pop" :class="[$i18n.locale, randomRoleInfo.fileName, `score_${resultContent.score}`]">
          <img src="@/assets/image/sgtest/result/bg_pop.png" alt="" />
          <p>{{ translate(resultContent.popText) }}</p>
        </div>
        <img v-if="resultContent.score < 60" :src="roleInfo.roleSad" alt="" class="role sad" :class="randomRoleInfo.fileName" />
        <img v-else :src="roleInfo.roleJoy" alt="" class="role joy" :class="randomRoleInfo.fileName" />
        <div class="share_tag share_tag_screenshot" :class="$i18n.locale">
          <img :src="roleInfo.tip2" alt="" />
          <div class="text">{{ translate("sgtest_bg.p51") }}</div>
        </div>
        <div class="share_tag" :class="$i18n.locale" :style="`background-image:url('${roleInfo.tip1}')`">
          {{ translate("sgtest_bg.p51") }}
        </div>
        <Btn class="reuslt_btn btn_download" @click.native="toDownloadApp">
          <img :src="downloadSrc" alt="" class="text" />
          <img src="@/assets/image/sgtest/result/btn_download/labmem_img_tips01.png" alt="" class="deco" />
          <img src="@/assets/image/sgtest/result/btn_download/labmem_img_tips03.png" alt="" class="deco_tips03" />
        </Btn>
        <Btn class="reuslt_btn btn_share" @click.native="toShare">
          <img :src="shareSrc" alt="" class="text" />
          <img src="@/assets/image/sgtest/result/btn_share/labmem_img_tips02.png" alt="" class="deco" />
        </Btn>
        <div class="reuslt_btn btn_save" @click="downloadResult">
          <img src="@/assets/image/sgtest/result/icon_save.png" alt="" />
          <div class="text">{{ translate("sgtest_bg.p48") }}</div>
        </div>
        <div class="reuslt_btn btn_retest" @click="retest">
          <img src="@/assets/image/sgtest/result/icon_retest.png" alt="" />
          <div class="text">{{ translate("sgtest_bg.p47") }}</div>
        </div>
      </div>
    </div>
    <CommonDialog v-model="shareDialog" className="share_dialog notranslate">
      <img class="title_img" slot="title" :src="dialogTitleSrc" alt="" />
      <ul class="share_list" slot="content">
        <li v-for="(item, index) in shareList" :key="index">
          <a href="javascript:;" @click="openShareWindow(item)">
            <img class="icon" :src="item.icon" alt="" />
            <div class="label">{{ item.label }}</div>
          </a>
        </li>
      </ul>
    </CommonDialog>
    <CommonDialog v-model="getAwardSuccessDialog" className="get_award_success_dialog notranslate">
      <div class="get_award_content" slot="content">
        <div class="content_main">
          <div class="text">{{ $t("survey_bg.p32") }}</div>
        </div>
        <a href="javascript:;" class="ok" @click="getAwardSuccessDialog = false">OK</a>
      </div>
    </CommonDialog>
    <CommonDialog v-model="saveImgDialog" overlay-opacity="0.8" :showClose="false" className="save_img_dialog notranslate">
      <div class="save_img_content" slot="content">
        <img class="header_img" slot="title" :src="downloadUrl" alt="" />
        <a href="javascript:;" class="ok" @click="saveImgDialog = false">OK</a>
      </div>
    </CommonDialog>
    <v-snackbar v-model="shareSuccessMsg" :centered="true" :timeout="5000" class="save_success_tip notranslate">
      <div class="success_msg">
        {{ $t("survey_bg.p21") }}
      </div>
    </v-snackbar>
    <v-snackbar v-model="saveSuccessMsg" :centered="true" :timeout="5000" class="save_success_tip notranslate">
      <div class="success_msg">
        {{ $t("survey_bg.p20") }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import DeviceDetector from "device-detector-js";
import html2canvas from "html2canvas";
import CommonDialog from "./CommonDialog.vue";
import ScoreNum from "./ScoreNum.vue";
import Btn from "./Btn.vue";
import share from "../mixins/share";
import { roleInfoList } from "../utils/data";
import { translate } from "../../../utils/utils";
export default {
  name: "Result",
  mixins: [share],
  components: {
    ScoreNum,
    Btn,
    CommonDialog,
  },
  props: {
    selectedAnswerList: {
      type: Array,
      required: true,
      default: () => [],
    },
    userInfo: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    isScreenshot: {
      type: Boolean,
      default: false,
    },
    passCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      roleInfoList,
      shareDialog: false,
      getAwardSuccessDialog: false,
      saveImgDialog: false,
      shareSuccessMsg: false,
      saveSuccessMsg: false,
      downloadUrl: "",
      randomRoleInfo: {
        fileName: "",
      },
    };
  },
  computed: {
    roleInfo() {
      const baseSrc = "assets/image/sgtest/result/";
      const fileName = this.randomRoleInfo.fileName;
      return {
        roleSad: require(`@/${baseSrc}${fileName}/role_sad.png`),
        roleJoy: require(`@/${baseSrc}${fileName}/role_joy.png`),
        bg: require(`@/${baseSrc}${fileName}/bg.png`),
        tip1: require(`@/${baseSrc}${fileName}/tip_1.png`),
        tip2: require(`@/${baseSrc}${fileName}/tip_2.png`),
      };
    },
    resultContent() {
      const score = this.selectedAnswerList.reduce((acc, { score }) => acc + score, 0);
      const popText = this.getPopText(score);
      return { ...this.randomRoleInfo, score, popText };
    },
    dialogTitleSrc() {
      return require(`@/assets/image/sgtest/dialog/share/labmem_text_share_${this.$i18n.locale}.png`);
    },
    currentLang() {
      return this.$i18n.locale;
    },
    downloadSrc() {
      return require(`@/assets/image/sgtest/result/btn_download/labmem_text_downloadbtn_${this.$i18n.locale}.png`);
    },
    shareSrc() {
      return require(`@/assets/image/sgtest/result/btn_share/labmem_text_sharebtn_${this.$i18n.locale}.png`);
    },
  },
  created() {
    this.randomRoleInfo = this.roleInfoList[this.randomNum(0, this.roleInfoList.length - 1)];
  },
  mounted() {
    this.sendLog({
      f3: "3",
      f4: "1",
      f5: this.userInfo.score,
    });
  },
  methods: {
    translate,
    retest() {
      this.$emit("retest");
      this.sendLog({
        f3: "3",
        f4: "3",
        f5: "0",
      });
    },
    downloadResult() {
      this.$emit("screenshot");
      this.$nextTick(async () => {
        const element = document.getElementById("screenshot_node");
        const options = {
          useCORS: true,
        };
        const deviceDetector = new DeviceDetector();
        const device = deviceDetector.parse(navigator.userAgent);
        if (device.os.name === "Windows") {
          options.width = element.clientWidth - 2;
        }
        html2canvas(element, options)
          .then((canvas) => {
            const dataUrl = canvas.toDataURL("image/png");
            if (this.isIOS()) {
              this.downloadUrl = dataUrl;
              this.saveImgDialog = true;
              return;
            } else {
              const link = document.createElement("a");
              link.download = `${this.userInfo.nickname}_${this.resultContent.score}.png`;
              link.href = dataUrl;
              link.click();
              link.remove();
              this.saveSuccessMsg = true;
            }
          })
          .then(() => {
            this.$emit("screenshoted");
          })
          .catch(function (error) {
            alert(`screenshot error : ${error}`);
          });
      });
      this.sendLog({
        f3: "3",
        f4: "2",
        f5: "0",
      });
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    getPopText(score) {
      const { poor, medium, good, excellent } = this.randomRoleInfo;
      if (score >= 100) {
        return excellent;
      } else if (score >= 80) {
        const index = this.randomNum(0, good.length - 1);
        return good[index];
      } else if (score >= 60) {
        return medium;
      } else {
        return poor;
      }
    },
    toDownloadApp() {
      const { Apple } = this.$store.state.downLink;
      if (this.isIOS()) {
        window.open(Apple);
      } else {
        window.open("/home");
      }
      this.sendLog({
        f3: "3",
        f4: "4",
        f5: "0",
      });
    },
    toShare() {
      this.shareDialog = true;
      this.sendLog({
        f3: "3",
        f4: "5",
        f5: "0",
      });
    },
    isIOS() {
      // 设备检测
      const deviceDetector = new DeviceDetector();
      const device = deviceDetector.parse(navigator.userAgent);
      return device.os?.name === "iOS";
    },
    sendLog(log) {
      this.$emit("sendLog", log);
    },
  },
};
</script>
<style lang="scss" scoped>
.Result {
  // position: relative;
  // z-index: 1;
  .result_content {
    max-height: calc(100vh - 9.6rem);
    .score_form {
      width: 68rem;
      border: solid 2px #797979;
      transform: rotate(-3deg);
      margin-left: 2rem;
      margin-bottom: 2.8rem;
      background-color: rgba($color: #fff, $alpha: 0.1);
      .nickname_row {
        display: flex;
        border-bottom: solid 2px #797979;
        .label {
          font-size: 3rem;
          line-height: 6.8rem;
          letter-spacing: 6rem;
          text-indent: 6rem;
          color: #6d6e71;
          border-right: solid 2px #797979;
          &.en {
            letter-spacing: 0;
            text-indent: 0;
            padding: 0 6rem;
          }
        }
        .value {
          flex: 1;
          font-size: 3.2rem;
          line-height: 6.8rem;
          color: #000000;
          text-align: center;
        }
      }
      .score_row {
        display: flex;
        height: 17.2rem;
        border-bottom: solid 2px #797979;
        .label {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 8rem;
          font-size: 3rem;
          line-height: 5rem;
          letter-spacing: 3rem;
          text-indent: 3rem;
          color: #6d6e71;
          writing-mode: vertical-rl;
          text-orientation: upright;
          &.en {
            writing-mode: inherit;
            letter-spacing: 0;
            text-indent: 0;
            transform: rotate(-90deg);
          }
        }
        .value {
          position: relative;
          flex: 1;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 6rem;
          border-left: solid 2px #797979;
          img {
            position: absolute;
            right: 2rem;
            bottom: 0.3rem;
            height: 18.3rem;
          }
        }
      }
      .desc {
        height: 6.4rem;
        line-height: 6.4rem;
        font-size: 3.2rem;
        color: #000000;
        text-align: center;
        .no {
          color: #ff5d15;
        }
      }
    }
    .role_section {
      position: relative;
      width: 75rem;
      height: 75.5rem;
      .banner {
        position: absolute;
        top: 8rem;
        left: 0;
      }
      .role {
        position: absolute;
        z-index: 3;
        width: 72rem;

        &.rintarou {
          left: 29rem;
          top: -6rem;
        }
        &.kurisu {
          left: 24rem;
          top: -30rem;
        }
        &.mayuri {
          left: 25rem;
          top: -30rem;
        }
        &.suzuha {
          left: 26rem;
          top: -28rem;
        }
      }
      .pop {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 47.5rem;
        height: 22.4rem;
        // background-image: url("../../../assets/image/sgtest/result/bg_pop.png");
        // background-repeat: no-repeat;
        // background-size: cover;
        font-size: 2.8rem;
        line-height: 4.2rem;
        color: #545454;
        padding: 3rem 9rem 5rem 3rem;
        margin-left: 2rem;
        margin-bottom: 18rem;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 47.5rem;
        }
        &.en {
          line-height: 3.2rem;
          &.rintarou.score_100 {
            font-size: 2.6rem;
            line-height: 3rem;
          }
        }
        p {
          transform: rotate(-3deg);
        }
      }
      .share_tag {
        position: relative;
        z-index: 1;
        display: inline-block;
        width: 32.1rem;
        height: 3.9rem;
        line-height: 3.9rem;
        padding-left: 1rem;
        padding-right: 1.8rem;
        font-size: 2.4rem;
        color: #ffffff;
        margin-bottom: 1.3rem;
        margin-left: 0.6rem;
        background-repeat: no-repeat;
        background-size: cover;
        text-align: center;
        &.share_tag_screenshot {
          width: 32.7rem;
          height: 5.6rem;
          line-height: 5.2rem;
          position: absolute;
          left: 1rem;
          top: 22rem;
          visibility: hidden;
          img {
            position: absolute;
            left: 0;
            top: 0;
            width: 32.7rem;
          }
          .text {
            transform: rotate(-3deg);
          }
        }
        &.en,
        &.tc,
        &.sc {
          font-size: 2.2rem;
          word-spacing: -1px;
        }
      }
      .Btn {
        position: relative;
        width: 26.3rem;
        height: 7.3rem;
        background-image: url("../../../assets/image/sgtest/result/btn_bg.png");
        margin-left: 4rem;
        margin-bottom: 3rem;
        padding-bottom: 0rem;
        padding-right: 0rem;
        &.btn_download {
          margin-bottom: 1.6rem;
          .text {
            width: 19.4rem;
          }
          .deco {
            position: absolute;
            left: 22.5rem;
            top: -9rem;
            z-index: 2;
            width: 22.5rem;
          }
          .deco_tips03 {
            position: absolute;
            left: 23rem;
            top: -1.5rem;
            z-index: 2;
            width: 0.5rem;
          }
        }
        &.btn_share {
          .text {
            width: 20.5rem;
          }
          .deco {
            position: absolute;
            left: 22.5rem;
            top: -9rem;
            z-index: 2;
            width: 22.5rem;
          }
        }
      }
      .btn_save,
      .btn_retest {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 18rem;
        height: 4.1rem;
        border-image-source: url("../../../assets/image/sgtest/result/btn_bg2.png");
        border-image-slice: 27 27 27 27 fill;
        border-image-width: 20px 20px 20px 20px;
        border-image-outset: 0px 0px 0px 0px;
        border-image-repeat: stretch stretch;
        padding-left: 2rem;
        padding-right: 1.8rem;
        margin-left: 4rem;
        img {
          flex-shrink: 0;
          display: block;
          width: 2.7rem;
          height: auto;
          margin-right: 1rem;
        }
        .text {
          flex: 1;
          font-size: 2.4rem;
          color: #ffffff;
          text-align: center;
        }
      }
      .btn_save {
        margin-bottom: 1rem;
      }
    }
  }
  .reuslt_btn {
    position: relative;
    z-index: 4;
    cursor: pointer;
  }
  &.screenshot {
    .reuslt_btn {
      visibility: hidden;
    }
    .share_tag {
      visibility: hidden;
      &.share_tag_screenshot {
        visibility: visible !important;
      }
    }
  }
}
</style>
<style lang="scss">
.share_dialog {
  .title {
    .title_img {
      width: 38rem !important;
    }
  }
  .share_list {
    padding: 0 4.5rem;
    li {
      width: 57.4rem;
      height: 11.4rem;
      padding-left: 0.7rem;
      padding-right: 5.4rem;
      background-image: url("../../../assets/image/sgtest/dialog/lang/labmem_frame_item.png");
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 2.6rem;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        .icon {
          height: 10rem;
          margin-right: 5rem;
        }
        .label {
          flex: 1;
          text-align: center;
          font-size: 4rem;
          color: #000000;
          font-weight: bold;
        }
        .check {
          position: relative;
          width: 4.8rem;
          height: 4.8rem;
          background-repeat: no-repeat;
          background-size: cover;
          background-image: url("../../../assets/image/sgtest/dialog/lang/check.png");
        }
      }

      &.active {
        .label {
          color: #000000;
        }
        .check {
          background-image: url("../../../assets/image/sgtest/dialog/lang/checked.png");
        }
      }
    }
  }
}
.get_award_success_dialog {
  .survey_dialog_body {
    padding-top: 12rem;
  }
  .get_award_content {
    position: relative;
    height: 100%;
    .content_main {
      display: block;
      width: 57.4rem;
      height: 59.2rem;
      background-image: url("../../../assets/image/sgtest/dialog/labmem_panel_rewards.png");
      background-size: cover;
      background-repeat: no-repeat;
      margin-left: 4.5rem;
      margin-bottom: 3rem;
      padding-top: 44rem;
    }
    .text {
      font-size: 3.2rem;
      line-height: 5rem;
      color: #ffffff;
      text-align: center;
      padding: 0 4rem;
    }
  }
}
.save_img_dialog {
  overflow-y: visible;
  .survey_dialog_body {
    // padding-top: 0px;
    background-image: url("../../../assets/image/sgtest/dialog/save/labmem_panel_screen.png");
    .save_img_content {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-left: 1.5rem;
      img {
        max-width: 45rem;
        max-height: 90%;
        border: 2px solid #4e4c4a;
        border-radius: 2px;
      }
      .ok {
        position: absolute;
        bottom: -3rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
.get_award_success_dialog,
.save_img_dialog {
  .ok {
    width: 30.4rem;
    height: 9.9rem;
    line-height: 9.9rem;
    text-align: center;
    background-image: url("../../../assets/image/sgtest/dialog/labmem_frame_okbtn.png");
    background-size: contain;
    background-repeat: no-repeat;
    font-size: 4rem;
    color: #000000;
    margin: 0 auto;
  }
}
.save_success_tip {
  z-index: 99 !important;
  .v-snack__wrapper {
    &.v-sheet {
      width: 53rem;
      height: 5rem;
      background-image: url("../../../assets/image/sgtest/do_test/labmem_frame_tips.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-color: transparent;
      color: #ffffff;
      box-shadow: none !important;
      min-width: auto;
      min-height: auto;
      .v-snack__content {
        .success_msg {
          height: 5rem;
          line-height: 5rem;
          font-size: 3rem;
          text-align: center;
        }
      }
    }
  }
}
</style>
