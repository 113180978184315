import { activityID } from "../utils/data";
import { shareConfig } from "../utils/config";
import { webShareGetAward } from "../utils/api";
export default {
  data() {
    return {
      shareDialog: false,
      getAwardSuccessDialog: false,
    };
  },
  computed: {
    shareText() {
      let textStr = "";
      const nickname = this.userInfo.nickname;
      const score = this.resultContent.score;
      const popText = this.$i18n.t(this.resultContent.popText);
      switch (this.$i18n.locale) {
        case "tc":
          textStr = `${nickname}，你的分數為${score}，${popText}`;
          break;
        case "sc":
          textStr = `${nickname}，你的分数为${score}，${popText}`;
          break;
        case "kr":
          textStr = `${nickname}, 그쪽의 점수는 ${score}, ${popText}`;
          break;
        case "jp":
          textStr = `${nickname}、あなたの点数は${score}です。${popText}`;
          break;
        default:
          textStr = `${nickname}, your final score is ${score}.${popText}`;
          break;
      }
      return textStr;
    },
    shareContent() {
      return {
        ...shareConfig,
        title: encodeURIComponent(this.shareText),
        text: encodeURIComponent(this.shareText),
        textWithUrl: encodeURIComponent(`${this.shareText} ${shareConfig.domain}`),
        tags: encodeURIComponent(`${this.$t(shareConfig.tag_1)},${this.$t(shareConfig.tag_2)},${this.$t(shareConfig.tag_3)}`),
        tagsWithsign: `#${this.$t(shareConfig.tag_1)}`,
      };
    },
    shareList() {
      const { title, text, textWithUrl, url, url_cn, tags } = this.shareContent;
      return [
        {
          label: "X",
          icon: require("@/assets/image/sgtest/dialog/share/labmem_icon_x.png"),
          intentUrl: `https://twitter.com/intent/tweet?text=${text}&url=${url}&hashtags=${tags}`,
          value: "x",
        },
        {
          label: "Facebook",
          icon: require("@/assets/image/sgtest/dialog/share/labmem_icon_fb.png"),
          intentUrl: "fb",
          value: "fb",
        },
        {
          label: "Line",
          icon: require("@/assets/image/sgtest/dialog/share/labmem_icon_line.png"),
          intentUrl: `https://social-plugins.line.me/lineit/share?url=${url}&text=${text}`,
          value: "line",
        },
        {
          label: "Whatsapp",
          icon: require("@/assets/image/sgtest/dialog/share/labmem_icon_whatsapp.png"),
          intentUrl: `https://api.whatsapp.com/send?text=${textWithUrl}`,
          value: "whatsapp",
        },
        {
          label: this.$t("survey_bg.p25"),
          icon: require("@/assets/image/sgtest/dialog/share/labmem_icon_weibo.png"),
          intentUrl: `https://service.weibo.com/share/share.php?url=${url_cn}&title=${title}`,
          value: "weibo",
        },
      ];
    },
  },
  methods: {
    share() {
      this.shareDialog = true;
      this.sendLog({
        f3: "3",
        f4: "5",
        f5: "0",
      });
    },
    openShareWindow(item) {
      this.shareSendLog(item.value);
      this.getShareAward();
      this.shareDialog = false;
      if (item.intentUrl === "fb") {
        this.facebookShare();
        return;
      }
      window.open(item.intentUrl, "_blank", "width=550,height=500");
    },
    facebookShare() {
      const { pureUrl, tagsWithsign } = this.shareContent;
      window.FB.ui(
        {
          method: "share",
          hashtag: tagsWithsign,
          href: pureUrl,
          display: "popup",
          redirect_uri: pureUrl, //用户在完成 Facebook 对话框操作后跳转的 URL 2024-05-13Facebook文档显示已弃用该配置
        },
        function (response) {
          console.log("response:", response);
        }
      );
    },
    async getShareAward() {
      try {
        const { uid, token } = this.$route.query;
        if (!uid || !token) return;
        const params = {
          token,
          userID: uid,
          activityID: activityID,
        };
        const res = await webShareGetAward(params);
        if (res.data.code !== 0) return;
        if (!res.data.award) return;
        this.getAwardSuccessDialog = true;
      } catch (e) {
        console.log(e);
      } finally {
        if (!this.getAwardSuccessDialog) {
          this.shareSuccessMsg = true;
        }
      }
    },
    shareSendLog(value) {
      const fMap = {
        x: { f5: "1" },
        fb: { f5: "3" },
        line: { f5: "2" },
        whatsapp: { f5: "4" },
        weibo: { f5: "0" },
      };
      this.sendLog({
        f3: "3",
        f4: "6",
        f5: fMap[value].f5,
      });
    },
  },
};
