export const pageState = {
  Entrance: "Entrance",
  EasterEgg: "EasterEgg",
  StartTest: "StartTest",
  DoTest: "DoTest",
  Result: "Result",
};
export const activityID = 262001; // 活动ID

export const questIdMap = {
  easy: { minId: 1, maxId: 199, count: 5 },
  normal: { minId: 201, maxId: 299, count: 3 },
  hard: { minId: 301, maxId: 399, count: 1 },
  mj: { minId: 401, maxId: 499, count: 1 },
};

export const surveyList = [
  {
    id: 101,
    question: "sgtest_quest.p2",
    options: [
      { id: 1011, label: "sgtest_quest.p3" },
      { id: 1012, label: "sgtest_quest.p4" },
      { id: 1013, label: "sgtest_quest.p5" },
    ],
    answer: 1011,
  },
  {
    id: 102,
    question: "sgtest_quest.p6",
    options: [
      { id: 1021, label: "sgtest_quest.p7" },
      { id: 1022, label: "sgtest_quest.p8" },
      { id: 1023, label: "sgtest_quest.p9" },
    ],
    answer: 1021,
  },
  {
    id: 103,
    question: "sgtest_quest.p10",
    options: [
      { id: 1031, label: "sgtest_quest.p11" },
      { id: 1032, label: "sgtest_quest.p12" },
      { id: 1033, label: "sgtest_quest.p13" },
    ],
    answer: 1032,
  },
  {
    id: 104,
    question: "sgtest_quest.p14",
    options: [
      { id: 1041, label: "sgtest_quest.p15" },
      { id: 1042, label: "sgtest_quest.p16" },
      { id: 1043, label: "sgtest_quest.p17" },
    ],
    answer: 1043,
  },
  {
    id: 105,
    question: "sgtest_quest.p18",
    options: [
      { id: 1051, label: "sgtest_quest.p19" },
      { id: 1052, label: "sgtest_quest.p20" },
      { id: 1053, label: "sgtest_quest.p21" },
    ],
    answer: 1052,
  },
  {
    id: 106,
    question: "sgtest_quest.p22",
    options: [
      { id: 1061, label: "sgtest_quest.p23" },
      { id: 1062, label: "sgtest_quest.p24" },
      { id: 1063, label: "sgtest_quest.p25" },
    ],
    answer: 1062,
  },
  {
    id: 107,
    question: "sgtest_quest.p26",
    options: [
      { id: 1071, label: "sgtest_quest.p27" },
      { id: 1072, label: "sgtest_quest.p28" },
      { id: 1073, label: "sgtest_quest.p29" },
    ],
    answer: 1071,
  },
  {
    id: 108,
    question: "sgtest_quest.p30",
    options: [
      { id: 1081, label: "sgtest_quest.p31" },
      { id: 1082, label: "sgtest_quest.p32" },
      { id: 1083, label: "sgtest_quest.p33" },
    ],
    answer: 1083,
  },
  {
    id: 109,
    question: "sgtest_quest.p34",
    options: [
      { id: 1091, label: "sgtest_quest.p35" },
      { id: 1092, label: "sgtest_quest.p36" },
      { id: 1093, label: "sgtest_quest.p37" },
    ],
    answer: 1093,
  },
  {
    id: 110,
    question: "sgtest_quest.p38",
    options: [
      { id: 1101, label: "sgtest_quest.p39" },
      { id: 1102, label: "sgtest_quest.p40" },
      { id: 1103, label: "sgtest_quest.p41" },
    ],
    answer: 1101,
  },
  {
    id: 201,
    question: "sgtest_quest.p42",
    options: [
      { id: 2011, label: "sgtest_quest.p43" },
      { id: 2012, label: "sgtest_quest.p44" },
      { id: 2013, label: "sgtest_quest.p45" },
    ],
    answer: 2013,
  },
  {
    id: 202,
    question: "sgtest_quest.p46",
    options: [
      { id: 2021, label: "sgtest_quest.p47" },
      { id: 2022, label: "sgtest_quest.p48" },
      { id: 2023, label: "sgtest_quest.p49" },
    ],
    answer: 2022,
  },
  {
    id: 203,
    question: "sgtest_quest.p50",
    options: [
      { id: 2031, label: "sgtest_quest.p51" },
      { id: 2032, label: "sgtest_quest.p52" },
      { id: 2033, label: "sgtest_quest.p53" },
    ],
    answer: 2031,
  },
  {
    id: 204,
    question: "sgtest_quest.p54",
    options: [
      { id: 2041, label: "sgtest_quest.p55" },
      { id: 2042, label: "sgtest_quest.p56" },
      { id: 2043, label: "sgtest_quest.p57" },
    ],
    answer: 2041,
  },
  {
    id: 205,
    question: "sgtest_quest.p58",
    options: [
      { id: 2051, label: "sgtest_quest.p59" },
      { id: 2052, label: "sgtest_quest.p60" },
      { id: 2053, label: "sgtest_quest.p61" },
    ],
    answer: 2051,
  },
  {
    id: 206,
    question: "sgtest_quest.p62",
    options: [
      { id: 2061, label: "sgtest_quest.p63" },
      { id: 2062, label: "sgtest_quest.p64" },
      { id: 2063, label: "sgtest_quest.p65" },
    ],
    answer: 2062,
  },
  {
    id: 301, // 17
    question: "sgtest_quest.p66",
    options: [
      { id: 3011, label: "sgtest_quest.p67" },
      { id: 3012, label: "sgtest_quest.p68" },
      { id: 3013, label: "sgtest_quest.p69" },
    ],
    answer: 3011,
  },
  {
    id: 302,
    question: "sgtest_quest.p70",
    options: [
      { id: 3021, label: "sgtest_quest.p71" },
      { id: 3022, label: "sgtest_quest.p72" },
      { id: 3023, label: "sgtest_quest.p73" },
    ],
    answer: 3021,
  },
  {
    id: 303,
    question: "sgtest_quest.p74",
    options: [
      { id: 3031, label: "sgtest_quest.p75" },
      { id: 3032, label: "sgtest_quest.p76" },
      { id: 3033, label: "sgtest_quest.p77" },
    ],
    answer: 3032,
  },
  {
    id: 304,
    question: "sgtest_quest.p78",
    options: [
      { id: 3041, label: "sgtest_quest.p79" },
      { id: 3042, label: "sgtest_quest.p80" },
      { id: 3043, label: "sgtest_quest.p81" },
    ],
    answer: 3041,
  },
  {
    id: 305,
    question: "sgtest_quest.p82",
    options: [
      { id: 3051, label: "sgtest_quest.p83" },
      { id: 3052, label: "sgtest_quest.p84" },
      { id: 3053, label: "sgtest_quest.p85" },
    ],
    answer: 3051,
  },
  {
    id: 401,  //22
    question: "sgtest_quest.p86",
    options: [
      { id: 4011, label: "sgtest_quest.p87" },
      { id: 4012, label: "sgtest_quest.p88" },
      { id: 4013, label: "sgtest_quest.p89" },
    ],
    answer: 4011,
  },
  {
    id: 402,
    question: "sgtest_quest.p90",
    options: [
      { id: 4021, label: "sgtest_quest.p91" },
      { id: 4022, label: "sgtest_quest.p92" },
      { id: 4023, label: "sgtest_quest.p93" },
    ],
    answer: 4021,
  },
  {
    id: 403,
    question: "sgtest_quest.p94",
    options: [
      { id: 4031, label: "sgtest_quest.p95" },
      { id: 4032, label: "sgtest_quest.p96" },
      { id: 4033, label: "sgtest_quest.p97" },
    ],
    answer: 4033,
  },
];

export const roleInfoList = [
  {
    fileName: "rintarou", // 冈部
    poor: "sgtest_bg.p2",
    medium: "sgtest_bg.p3",
    good: ["sgtest_bg.p4", "sgtest_bg.p5"],
    excellent: "sgtest_bg.p6",
  },
  {
    fileName: "mayuri", // 椎名真由理
    poor: "sgtest_bg.p7",
    medium: "sgtest_bg.p8",
    good: ["sgtest_bg.p9", "sgtest_bg.p10"],
    excellent: "sgtest_bg.p11",
  },
  {
    fileName: "kurisu", // 牧濑红莉栖
    poor: "sgtest_bg.p12",
    medium: "sgtest_bg.p13",
    good: ["sgtest_bg.p14", "sgtest_bg.p15"],
    excellent: "sgtest_bg.p16",
  },
  {
    fileName: "suzuha", // 阿万音铃羽
    poor: "sgtest_bg.p17",
    medium: "sgtest_bg.p18",
    good: ["sgtest_bg.p19", "sgtest_bg.p20"],
    excellent: "sgtest_bg.p21",
  },
];
