<template>
  <div class="StartTest">
    <img class="title" :src="titleSrc" alt="" />
    <div class="name_input_wrap">
      <div class="header" :class="$i18n.locale">{{ $t("sgtest_bg.p40") }}</div>
      <div class="bottom">
        <div class="input_wrap">
          <input type="text" v-model="nickname" :placeholder="$t('sgtest_bg.p55')" maxlength="16" @focus="inputFocus" @blur="inputBlur" />
          <div class="line"></div>
          <div v-show="errorTip" class="error_tip" :class="$i18n.locale">{{ errorTip ? $t(`sgtest_bg.${errorTip}`) : "" }}</div>
        </div>
      </div>
    </div>
    <Btn :loading="checkLoading" @click.native="toDoTest">
      <img :src="toDoTestText" alt="" class="to_do_test_text" />
      <img src="@/assets/image/sgtest/do_test/deco_02.png" alt="" class="deco_02" />
    </Btn>
  </div>
</template>

<script>
import Btn from "./Btn.vue";
import CryptoJS from "crypto-js";
import { sensitiveWordCheck } from "../utils/api";
import { isIOS } from "../utils/utils";
export default {
  name: "StartTest",
  components: {
    Btn,
  },
  data() {
    return {
      nickname: "",
      errorTip: "",
      checkLoading: false,
      lastInvalidNickname: "",
    };
  },
  computed: {
    titleSrc() {
      return require(`@/assets/image/sgtest/start_test/labmem_text_title_${this.$i18n.locale}.png`);
    },
    toDoTestText() {
      return require(`@/assets/image/sgtest/start_test/labmem_text_btntest_${this.$i18n.locale}.png`);
    },
  },
  mounted() {
    this.sendLog({
      f3: "1",
      f4: "2",
      f5: "0",
    });
  },
  methods: {
    async toDoTest() {
      const isValid = await this.validate();
      if (!isValid) return;
      this.$emit("toDoTest", this.nickname);
      this.sendLog(
        {
          f3: "1",
          f4: "2",
          f5: "2",
        },
        true
      );
    },
    async validate() {
      const length = this.getNicknameLength(this.nickname);
      if (length === 0) {
        this.errorTip = "p54";
        return false;
      } else if (length > 16) {
        this.errorTip = "p53";
        return false;
      }
      /*
        content string // 内容
        token string // content拼接websensitivecheck0816字符串，SHA256加密
       */
      if (this.checkLoading) return;
      if (this.nickname === this.lastInvalidNickname) {
        this.errorTip = "p52";
        return false;
      }
      const content = this.nickname;
      const token = await this.sha256(content + "websensitivecheck0816");
      try {
        this.checkLoading = true;
        const res = await sensitiveWordCheck({ content, token });
        if (!res.data.valid) {
          this.errorTip = "p52";
          this.lastInvalidNickname = this.nickname;
          return false;
        }
        this.errorTip = "";
        return true;
      } catch (error) {
        return false;
      } finally {
        this.checkLoading = false;
      }
    },
    sha256(message) {
      return CryptoJS.SHA256(message).toString(CryptoJS.enc.Hex);
    },
    getNicknameLength(nickname) {
      let length = 0;
      for (let char of nickname) {
        if (char.match(/[a-zA-Z]/)) {
          length += 1;
        } else {
          length += 2;
        }
      }
      return length;
    },
    inputFocus() {
      this.sendLog({
        f3: "1",
        f4: "2",
        f5: "1",
      });
    },
    inputBlur() {
      if (!isIOS()) return;
      window.scroll(0, 0);
    },
    sendLog(...arg) {
      this.$emit("sendLog", ...arg);
    },
  },
};
</script>
<style lang="scss" scoped>
.StartTest {
  // margin-top: -10rem;
  .title {
    width: 67rem;
    margin-bottom: 11rem;
  }
  .name_input_wrap {
    width: 67rem;
    border: solid 2px #797979;
    margin: 0 auto 9rem;
    background-color: rgba($color: #fff, $alpha: 0.3);
    .header {
      display: flex;
      justify-content: center;
      height: 8rem;
      border-bottom: solid 2px #797979;
      text-align: center;
      font-size: 3.4rem;
      line-height: 8rem;
      letter-spacing: 6.8rem;
      text-indent: 6.8rem;
      color: #6d6e71;
      &.en {
        letter-spacing: 1px;
        text-indent: 0;
      }
    }
    .bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35rem;
      .input_wrap {
        position: relative;
        width: 60rem;
        input {
          width: 100%;
          text-align: center;
          outline: none;
          font-size: 3.8rem;
          &::placeholder {
            font-size: 3rem;
            color: #b8b8b8;
          }
        }
        .line {
          box-sizing: border-box;
          height: 5px;
          border-top: 2px solid #797979;
          border-bottom: 2px solid #797979;
        }
        .error_tip {
          position: absolute;
          left: 0;
          bottom: -0.8rem;
          transform: translateY(100%);
          width: 100%;
          font-size: 2.4rem;
          color: #d15a63;
          text-align: center;
          &.jp {
            letter-spacing: -1px;
          }
        }
      }
    }
  }
  .Btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44rem;
    height: 12rem;
    background-image: url("../../../assets/image/sgtest/easter_egg/labmemtest_frame_nextbtn.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    .to_do_test_text {
      display: block;
      width: 24rem;
      margin-top: 0.6rem;
    }
    .deco_02 {
      position: absolute;
      height: 13.6rem;
      left: -16rem;
      top: 7.7rem;
    }
  }
  .fullscreen_loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
