<template>
  <div class="Btn" :class="{ loading }">
    <slot></slot>
    <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "Btn",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.Btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44rem;
  height: 12rem;
  background-image: url("../../../assets/image/sgtest/easter_egg/labmemtest_frame_nextbtn.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto;
  padding-bottom: 1.6rem;
  padding-right: 0.8rem;
  cursor: pointer;
  img {
    display: block;
    width: 22.5rem;
  }
  &.loading {
    position: relative;
    pointer-events: none;
    cursor: not-allowed;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 1;
    }
    .v-progress-circular {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 2;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
