<template>
  <div class="Entrance">
    <div v-show="isShowProgress" class="progress">
      <div class="center">
        <div class="bar_bg">
          <div class="bar" :style="{ width: progressWidth }"></div>
        </div>
        <div class="progress_num">{{ progressNum }}%</div>
      </div>
    </div>
    <main v-show="isShowMain" :class="{ visible: mainVisible }">
      <img class="title" :src="titleSrc" alt="" />
      <div class="text_content">
        <p :class="$i18n.locale">{{ translate("sgtest_bg.p32") }}</p>
      </div>
      <div class="condition">
        <div class="condition_1">
          <div class="text">{{ translate("sgtest_bg.p33") }}</div>
        </div>
        <div class="condition_2">
          <div class="text" v-html="translate('sgtest_bg.p34')"></div>
        </div>
      </div>
      <div class="btns">
        <div class="btn btn_selected confirm" :class="$i18n.locale" @click="startTest">
          <img :src="confirmTextSrc" alt="" />
        </div>
        <div class="btn btn_selected deny" :class="$i18n.locale" @click="denyTest">
          <img :src="denyTextSrc" alt="" />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import gsap from "gsap";
import { translate } from "../../../utils/utils";
export default {
  name: "Entrance",
  data() {
    return {
      isShowProgress: true,
      isShowMain: true,
      mainVisible: false,
      progressNum: 0,
    };
  },
  computed: {
    titleSrc() {
      return require(`@/assets/image/sgtest/entrance/labmemtest_title_${this.$i18n.locale}.png`);
    },
    confirmTextSrc() {
      return require(`@/assets/image/sgtest/entrance/labmemtest_text_btn01_${this.$i18n.locale}.png`);
    },
    denyTextSrc() {
      return require(`@/assets/image/sgtest/entrance/labmemtest_text_btn02_${this.$i18n.locale}.png`);
    },
    progressWidth() {
      return `${this.progressNum}%`;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    translate,
    startTest() {
      this.$emit("startTest");
    },
    denyTest() {
      this.$emit("denyTest");
    },
    sendLog(log) {
      this.$emit("sendLog", log);
    },
    init() {
      this.progressNum += this.randomNum(10, 20);
      if (this.progressNum < 100) {
        setTimeout(() => {
          this.init();
        }, 100);
      } else {
        this.mainVisible = true;
        this.isShowProgress = false;
        this.isShowMain = false;
        this.$nextTick(() => {
          this.isShowMain = true;
          gsap.from(".Entrance main", {
            scale: 1,
            x: "150%",
            y: 100,
            duration: 1,
            rotation: 90,
          });
        });
      }
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
  },
};
</script>
<style lang="scss" scoped>
.Entrance {
  width: 100%;
  .progress {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 67.8rem;
    height: 18.6rem;
    background-image: url("../../../assets/image/sgtest/entrance/progress/progress_frame.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    .bar_bg {
      width: 61.1rem;
      height: 4.9rem;
      background-image: url("../../../assets/image/sgtest/entrance/progress/progress_bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      padding-top: 0.7rem;
      padding-left: 0.5rem;
      .bar {
        max-width: 60.2rem;
        height: 3.4rem;
        background-image: url("../../../assets/image/sgtest/entrance/progress/progress_bar.png");
        background-repeat: no-repeat;
        background-size: 60.2rem 3.4rem;
        transition: all 0.1s;
      }
    }
    .progress_num {
      width: 100%;
      font-size: 4rem;
      line-height: 4.8rem;
      color: #1477ce;
      text-align: center;
    }
  }
  main {
    visibility: hidden;
    position: relative;
    width: 75rem;
    height: 108.3rem;
    background-image: url("../../../assets/image/sgtest/entrance/labmemtest_panel_parper.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 20rem;
    &.visible {
      visibility: visible;
    }
    .title {
      position: absolute;
      left: 4rem;
      top: -5.6rem;
      width: 65rem;
    }
    .text_content {
      padding: 0 4.5rem;
      transform: rotate(-3deg);
      margin-bottom: 5.6rem;
      p {
        font-size: 3.4rem;
        line-height: 6.8rem;
        color: #6d6e71;
        white-space: pre-wrap;
        &.en {
          line-height: 4.8rem;
        }
      }
    }
    .condition {
      padding: 0 4.5rem;
      margin-bottom: 10rem;
      > div {
        background-repeat: no-repeat;
        background-size: cover;
        color: #f6f6f6;
        padding-left: 2rem;

        .text {
          transform: rotate(-3deg);
        }
      }
      .condition_1 {
        width: 29rem;
        height: 6rem;
        line-height: 6rem;
        background-image: url("../../../assets/image/sgtest/entrance/labmemtest_frame_condition_1.png");
        font-size: 3rem;
      }
      .condition_2 {
        width: 44rem;
        height: 7rem;
        line-height: 7rem;
        background-image: url("../../../assets/image/sgtest/entrance/labmemtest_frame_condition_2.png");
        font-size: 2.8rem;
        .text {
          span {
            color: #3c3a43;
          }
        }
      }
    }
    .btns {
      position: absolute;
      top: 89rem;
      left: 0;
      display: flex;
      margin-left: 6.2rem;
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 31.9rem;
        height: 14.8rem;
        background-repeat: no-repeat;
        background-size: cover;
        // padding-top: 1rem;
        // padding-left: 1rem;
        padding-bottom: 1.6rem;

        &.confirm {
          background-image: url("../../../assets/image/sgtest/entrance/labmemtest_frame_btn01.png");
          margin-right: 4rem;
          // padding-top: 1rem;
          // padding-left: 2rem;
          &.en {
            padding-right: 1rem;
          }
        }
        &.deny {
          background-image: url("../../../assets/image/sgtest/entrance/labmemtest_frame_btn02.png");
          // padding-top: 1rem;
          &.en {
            padding-right: 1rem;
          }
        }
        img {
          display: block;
          height: 6.8rem;
        }
      }
    }
  }
}
</style>
