import axios from "@/utils/request";

export const sensitiveWordCheck = (data) => {
  return axios.post(`/mixed_client/webSensitiveWordCheck`, data);
};

export const webShareActivityInfo = (data) => {
  return axios.post(`/activity/webShareActivityInfo`, data);
};

export const webShareActivitySubmit = (data) => {
  return axios.post(`/activity/webShareActivitySubmit`, data);
};

export const webSendFlowLog = (data) => {
  return axios.post(`/activity/webSendFlowLog`, data);
};
/* --- */
export const webShareGetAward = (data) => {
  return axios.post(`/activity/webShareGetAward`, data);
};
export const webShareCheckAuth = (password) => {
  return axios.post(`/activity/webShareCheckAuth`, { password });
};
