import CryptoJS from "crypto-js";
import DeviceDetector from "device-detector-js";

export const sha256 = (message) => {
  return CryptoJS.SHA256(message).toString(CryptoJS.enc.Hex);
};

export const isIOS = () => {
  // 设备检测
  const deviceDetector = new DeviceDetector();
  const device = deviceDetector.parse(navigator.userAgent);
  return device.os?.name === "iOS";
};
