<template>
  <div class="CommonDialog">
    <v-dialog v-model="showDialog" :content-class="`survey_dialog ${className}`" v-bind="$attrs" overlay-opacity="0.8">
      <div class="survey_dialog_body">
        <a v-if="showClose" href="javascript:;" class="close_btn" @click="showDialog = false">
          <img src="@/assets/image/sgtest/dialog/labmem_btn_clo.png" alt="" />
        </a>
        <div class="title">
          <slot name="title"></slot>
        </div>
        <slot name="content"></slot>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "CommonDialog",
  props: {
    value: Boolean,
    className: {
      type: String,
      default: "",
    },
    showClose: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss">
.survey_dialog {
  width: 75rem !important;
  margin: 0;
  box-shadow: none;
  .survey_dialog_body {
    position: relative;
    width: 66.4rem;
    height: 89.8rem;
    background-image: url("../../../assets/image/sgtest/dialog/labmem_panel_popwin.png");
    background-repeat: no-repeat;
    background-size: 66.4rem 87.8rem;
    background-position-y: bottom;
    margin: 0 auto;
    padding-top: 15rem;
    .close_btn {
      position: absolute;
      right: 3rem;
      top: 5rem;
      z-index: 99;
      width: 5.6rem;
    }
    .title {
      position: absolute;
      top: 0rem;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 26.1rem;
        display: block;
      }
    }
  }
}
</style>
